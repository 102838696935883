.ui.button {
  font-weight: normal;
}

.blocklyTreeLabel {
  color: #fff;
}

.blocklyTreeRow {
  border-left: none !important;
}

.blocklyTreeIcon .icon {
  line-height: 0.8;
  font-size: 1.2em;
  color: #ffffff;
}

div.blocklyTreeRow:not(.blocklyTreeSelected):hover {
  background-color: rgba(0, 0, 0, 0.29);
}

div[class^="toolbox-cat-"] > div:nth-child(2) .blocklyTreeIcon {
  font-size: 1.6em;
  margin-left: 7px;
  color: #ffffff;
}

.toolbox-cat-advanced > div:nth-child(2) > div:nth-child(1) {
  background-color: #9a5ca6;
  .blocklyTreeIcon:before {
    content: "\f013";
  }
}

.toolbox-cat-advanced > div:nth-child(2) > div:nth-child(2) {
  background-color: #745ba5;
  .blocklyTreeIcon:before {
    content: "\f46d";
  }
}

.toolbox-cat-advanced > div:nth-child(2) > div:nth-child(3) {
  background-color: #5ba58c;
  .blocklyTreeIcon:before {
    content: "\f031";
  }
}

.toolbox-cat-advanced > div:nth-child(2) > div:nth-child(4) {
  background-color: #b22222;
  .blocklyTreeIcon:before {
    content: "\f2db";
  }
}

.toolbox-cat-advanced > div:nth-child(2) > div:nth-child(5) {
  background-color: #002050;
  .blocklyTreeIcon:before {
    content: "\f287";
  }
}

#pythonEditor {
  width: 100% !important;
  overflow-x: auto !important;
}

.ui.modal.hintdialog .ui.segment {
  display: block;
  text-align: center;
}

#tutorial-progress {
  font-weight: bold;
}

.ui.modal.hintdialog .content img {
  max-width: 100%;
}

block {
  padding: 0.05rem 0.2rem;
  font-family: Monaco, Menlo, Ubuntu Mono, Consolas, source-code-pro, monospace !important;
  font-size: 1em !important;
  border-radius: 0.2rem;
  white-space: nowrap;
  color: #fff;
  background-color: rgb(30, 144, 255);
}

block.basic {
  background-color: rgb(30, 144, 255);
}

block.input {
  background-color: rgb(212, 0, 212);
}

block.music {
  background-color: rgb(230, 48, 34);
}

block.led {
  background-color: rgb(93, 44, 145);
}

block.wifi {
  background-color: rgb(227, 0, 140);
}

block.loops {
  background-color: rgb(66, 191, 66);
}

block.logic {
  background-color: rgb(66, 129, 191);
}

block.variables {
  background-color: rgb(191, 66, 129);
}

block.math {
  background-color: rgb(66, 87, 191);
}

block.advanced {
  background-color: rgb(103, 91, 165);
}

block.functions {
  background-color: rgb(170, 66, 191);
}

block.lists {
  background-color: rgb(108, 66, 191);
}

block.text {
  background-color: rgb(66, 191, 149);
}

block.pins {
  background-color: rgb(178, 34, 34);
}

block.gray {
  background-color: #888;
}

.avatar-image {
  width: 3rem;
  height: 3rem;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("/assets/common/yoyo.png");
  cursor: pointer;
  margin-top: 5px;
}

#notePicker {
  background-image: url("/assets/common/notes.png");
  border: 1px solid #ccc;
  height: 109px;
  width: 46px;
}

.remove-extension {
  position: absolute;
  top: 0;
  right: 0;
  i {
    font-size: 20px !important;
    opacity: 1 !important;
    margin: 0 !important;
  }
}

.ui.grid.device-card-list {
  justify-content: center;
  .device-card-item {
    margin: 10px;
    .body {
      color: hsla(0, 0%, 100%, 0.9);
      margin-top: 10px;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
  .device-card-item:hover {
    border-color: #fff;
  }
  .ui.image {
    height: auto;
  }
}
.ui.button.select-btn {
  border-radius: 0 0.28571429rem 0.28571429rem 0;
  border-left: 1px solid #545454;
  font-weight: bolder;
  position: relative;
  .menu {
    position: absolute;
    top: -85px;
  }
}

.name-device-editor-toobar {
  padding: 10px;
  color: white;
  font-size: 1.14285714rem;
}

#chart-serial {
  background-color: white;
  overflow: auto;
  color: black;
  height: 60vh;
}

@media only screen and (max-width: 667px) {
  #editortools span {
    display: none;
  }
  .device-menu-item span {
    display: inline !important;
  }
}
@media only screen and (min-width: 568px) {
  .name-device-editor-toobar {
    display: inline !important;
  }
}
@media only screen and (max-width: 360px) {
  .editor-toolbar {
    .left {
      padding-right: 0 !important;
    }
    .center {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
    .right {
      padding-left: 0 !important;
    }
  }
}
